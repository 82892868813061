<template>
  <v-card flat>
    <v-card-text>
      <h3 class="red--text">
        {{ anonymities.name }}
      </h3>
      <v-radio-group
        v-model="election.configuration_attributes.anonymity_id"
        :error-messages="this.errors['configuration.anonymity_id']"
      >
        <v-radio
          v-for="anonymity in anonymities.anonymities"
          :key="anonymity.id"
          :label="`${anonymity.name}: ${anonymity.description}`"
          :value="anonymity.id"
          @click="updateElectionConfiguration"
          :disabled="!canEdit"
        ></v-radio>
      </v-radio-group>

      <h3 class="red--text">
        {{ resultsViewableDurings.name }}
      </h3>
      <v-radio-group
        v-model="election.configuration_attributes.results_viewable_during"
        :error-messages="this.errors['configuration.results_viewable_during']"
      >
        <v-radio
          v-for="resultsViewableDuring in resultsViewableDurings.results_viewable_durings"
          :key="resultsViewableDuring.id"
          :label="`${resultsViewableDuring.name}`"
          :value="resultsViewableDuring.id"
          @click="updateElectionConfiguration"
          :disabled="!canEdit"
        ></v-radio>
      </v-radio-group>

      <h3 class="red--text">
        {{ resultsViewableAfters.name }}
      </h3>
      <v-radio-group
        v-model="election.configuration_attributes.results_viewable_after"
        :error-messages="this.errors['configuration.results_viewable_after']"
      >
        <v-radio
          v-for="resultsViewableAfter in resultsViewableAfters.results_viewable_afters"
          :key="resultsViewableAfter.id"
          :label="`${resultsViewableAfter.name}`"
          :value="resultsViewableAfter.id"
          @click="updateElectionConfiguration"
          :disabled="!canEdit"
        ></v-radio>
      </v-radio-group>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('questions')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('configuration')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import anonymities from "@/services/anonymities";
import resultsViewableDurings from "@/services/results_viewable_durings";
import resultsViewableAfters from "@/services/results_viewable_afters";
import updateElectionConfiguration from "@/mixins/updateElectionConfiguration";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotFeatures",
  mixins: [updateElectionConfiguration, electionCanEdit],
  props: ["election"],
  data: () => ({
    anonymities: [],
    resultsViewableDurings: [],
    resultsViewableAfters: []
  }),
  mounted() {
    anonymities.get().then(response => {
      this.anonymities = response.data;
    });
    resultsViewableDurings.get().then(response => {
      this.resultsViewableDurings = response.data;
    });
    resultsViewableAfters.get().then(response => {
      this.resultsViewableAfters = response.data;
    });
  },
  methods: {
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>